import React from 'react';
import { Link } from 'react-router-dom';
import logoImg from '../../img/icons/nm_logo/main_logo_color_with_text.svg';
import { FaDiscord, FaEnvelope, FaLinkedin, FaTwitter } from 'react-icons/fa';
import { CgIndieHackers } from 'react-icons/cg';

export default function FooterComponent() {
  return (
    <div>
      <footer className="footer p-10 bg-main-backgroundFooter text-main-letters">
        <aside className=" text-white">
          <div className="flex">
            <img src={logoImg} alt="logo" className="w-24" />
            <p className="m-auto">
              Belgrade and Novi Sad,
              <br />
              Serbia
            </p>
          </div>
        </aside>
        <nav>
          <header className="footer-title">Say Hi</header>
          <div className="grid grid-flow-col gap-4">
            <a
              href="https://discord.gg/cDNXSjukZh"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaDiscord className="hover:text-white" size={24} />
            </a>
            <a
              href="https://twitter.com/notify_me_app"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaTwitter className="hover:text-white" size={24} />
            </a>
            <a
              href="https://www.linkedin.com/company/notify-me-rs"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaLinkedin className="hover:text-white" size={24} />
            </a>
            <a href="mailto:office@notify-me.rs">
              <FaEnvelope className="hover:text-white" size={24} />
            </a>
            <a
              href="https://www.indiehackers.com/product/notify-me"
              target="_blank"
              rel="noopener noreferrer"
            >
              <CgIndieHackers className="hover:text-white" size={24} />
            </a>
          </div>
        </nav>
        <nav>
          <header className="footer-title">Have a question?</header>
          <div className="grid grid-flow-col gap-4">
            <p className="text-white">
              Feel free to reach out to{' '}
              <a
                className="text-main-letters hover:underline"
                href="https://twitter.com/imafikus"
                target="_blank"
                rel="noopener noreferrer"
              >
                @imafikus
              </a>
            </p>
          </div>
        </nav>
        <nav>
          <header className="footer-title">Company</header>
          <p className="mb-1 hover:underline underline lg:no-underline text-main-footerLinks">
            <a href="mailto:office@notify-me.rs">Contact</a>
          </p>
          <p className="mb-1 hover:underline underline lg:no-underline text-main-footerLinks">
            <Link to="/privacy_policy">Privacy Policy</Link>
          </p>
          <p className="mb-1 hover:underline underline lg:no-underline text-main-footerLinks">
            <Link to="/terms_of_use">Terms of Use</Link>
          </p>
          <p className="hover:underline underline lg:no-underline text-main-footerLinks">
            <Link to="/blog">Blog</Link>
          </p>
        </nav>
        <nav>
          <header className="footer-title">Explore</header>
          <p className="hover:underline underline lg:no-underline text-main-footerLinks mb-1">
            <Link to="/">
              <span>Home</span>
            </Link>
          </p>
          <p className="hover:underline underline lg:no-underline text-main-footerLinks mb-1">
            <Link to="/how_it_works">
              <span>How It Works</span>
            </Link>
          </p>
          <p className="hover:underline underline lg:no-underline text-main-footerLinks mb-1">
            <Link to="/login">
              <span>Log In</span>
            </Link>
          </p>
          <p className="hover:underline underline lg:no-underline text-main-footerLinks mb-1">
            <Link to="/signup">
              <span>Sign up</span>
            </Link>
          </p>
          <p className="hover:underline underline lg:no-underline text-main-footerLinks mb-1">
            <Link to="/pricing">
              <span>Pricing</span>
            </Link>
          </p>
          <p className="hover:underline underline lg:no-underline text-main-footerLinks mb-1">
            <Link to="/earn">
              <span>Affiliates</span>
            </Link>
          </p>
        </nav>
      </footer>
      <div className="bg-main-backgroundFooter text-center p-5 text-sm lg:text-base block border-solid border-t border-main-letters">
        <p>&copy; Copyright Notify Me {new Date().getFullYear()}.</p>
      </div>
    </div>
  );
}
